import {get, post} from '../http';

const fetch = id => get('sessionRetrieve?id='+id);
const save = (id, data) => post('sessionUpdate?id='+id, data);
const toggle = (id, cancel) => post('session'+(cancel ? 'Cancel' : 'Republish')+'?id='+id);
const register = (id, registrants) => post('sessionRegister?id='+id, {registrants})
const unregister = (id, registrant_ids) => post('sessionRegistrantsUnregister?id='+id, {registrant_ids})
const withdraw = id => post('sessionWithdraw?id='+id)
const attendance = (id, data) => post('sessionAttendanceUpdate?id='+id, data)
const registrantsAttendance = (id, data) => post('sessionRegistrantsAttendanceUpdate?id='+id, data)
const contact = (id, data) => post('sessionContact?id='+id, data)

export default {
  fetch,
  save,
  toggle,
  register,
  unregister,
  withdraw,
  attendance,
  registrantsAttendance,
  contact
};
