<template>
  <v-date-picker
    v-model="dates"
    range
  />
</template>

<script>
export default {
  name: 'EppJoinedDatePicker',
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    const defaultDates = [new Date().toISOString().split('T')[0]];
    return {
      defaultDates,
      dates: this.parseInputString(this.value) || defaultDates
    };
  },
  methods: {
    parseInputString(val) {
      if (!val) {
        return;
      }
      return val.split(' - ')
    },
    updateDates(val) {
      this.ignoreChange = true;
      this.dates = this.parseInputString(val) || this.defaultDates;
      this.$nextTick(() => {
        this.ignoreChange = false;
      });
    }
  },
  watch: {
    dates(val) {
      if (this.ignoreChange) {
        // So that defaultDates are not propagated up
        return;
      }
      if (val.length == 2) {
        if (val[0] == val[1]) {
          // Do not allow a date range that starts and ends on the same day
          this.dates = [val[0]];
        } else if (new Date(val[0]) > new Date(val[1])) {
          // Ensure that the first date is before the second date
          this.dates = [val[1], val[0]];
        }
      }
      this.$emit('input', this.dates.join(' - '));
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@nswdoe/doe-ui-core/styles/variables.scss';

// Join the date picker dates with a bar, rather than dots
.v-picker--date:deep {
  .v-btn--active:not(.v-date-picker--first-in-range.v-date-picker--last-in-range) {
    &:not(.v-date-picker--first-in-range):not(.v-date-picker--last-in-range) {
      color: $ads-dark-80;
      background-color: transparent !important;
      &::before {
        display: none;
      }
    }
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $ads-custom-navy-2;
      z-index: -1;
    }
  }
  tr td:not(:first-child) .v-btn--active {
    &:not(.v-date-picker--first-in-range)::after {
      left: -6px;
      width: calc(100% + 6px);
    }
    &.v-date-picker--first-in-range::after {
      left: 50%;
    }
    &.v-date-picker--last-in-range::after {
      width: calc(50% + 6px);
    }
  }
}
</style>
