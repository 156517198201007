import { get, post } from '../http';

const fetch = (id) => get(id ? 'eventsRetrieve?id=' + id : 'eventsRetrieve');
const save = (id, data) => {
  let url = 'eventSave';
  if (id) {
    url += '?id=' + id;
  }
  return post(url, data);
};
const cancel = (data) => post('eventDelete', data);
const createSession = (id, data) => post('sessionCreate?id=' + id, data);
const duplicate = (id) => post('eventDuplicate', { id });
const saveOtherAttendeeData = (id, data) => post('eventSaveOtherAttendeeData?id=' + id, data);
const exportToCSV = () => get('eventsCSVExport');

export default {
  fetch,
  save,
  cancel,
  createSession,
  duplicate,
  saveOtherAttendeeData,
  exportToCSV
};
