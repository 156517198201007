import { get, post } from '../http';

const fetch = () => get('regionsRetrieve');
const save = (id, data) => {
  let url = 'regionSave';
  if (id) {
    url += '?id=' + id;
  }
  return post(url, data);
};

export default {
  fetch,
  save,
};
