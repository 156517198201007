<template>
  <v-container>
    <v-row>
      <v-col>
        <!-- Left Panel: Search and List -->
        <v-card>
          <v-card-title>
            <v-row>
              <v-col
                cols="12"
                class="headline"
              >
                Search
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  placeholder="Search"
                  single-line
                  hide-details
                  outlined
                />
              </v-col>
            </v-row>
          </v-card-title>

          <!-- Chip Filters in a Row -->
          <v-row
            no-gutters
            style="padding: 0 16px"
          >
            <!-- Year Level Filter -->
            <v-col
              cols="auto"
              class="pr-2"
            >
              <ChipFilter
                v-model="selectedYearLevels"
                type="checkbox"
                :items="availableYears"
                heading="Year level"
                name="Year level"
              />
            </v-col>

            <!-- School Filter -->
            <v-col cols="auto">
              <ChipFilter
                v-model="selectedSchools"
                type="checkbox"
                :items="availableSchools"
                heading="School"
                name="School"
              />
            </v-col>

            <v-col
              cols="auto"
              class="pl-6 align-self-center"
            >
              <span
                @click="clearAllFilters"
                style="cursor: pointer; font-weight: bold"
              >
                Clear all
              </span>
            </v-col>
          </v-row>

          <v-card-actions>
            <v-checkbox
              :input-value="isAllSelected"
              :indeterminate="isIndeterminate"
              @click.stop="toggleAllSelection"
            />
            <span>Select all</span>
            <v-spacer />
            <AdsButton
              icon="add_circle_outline"
              buttonText="Register students"
              @click="registerSelected()"
            />
          </v-card-actions>

          <v-divider />

          <v-list style="max-height: 50vh; overflow-y: auto">
            <div
              v-if="filteredStudents.length === 0"
              class="pa-4"
            >
              Loading students...
            </div>
            <v-list-item-group
              v-model="selectedStudentSRNs"
              multiple
              active-class="primary--text"
            >
              <v-list-item
                v-for="student in filteredStudents"
                :key="student.studentRegistrationNumber"
                :class="{ 'greyed-out': isRegistered(student) }"
                @click="toggleSelection(student)"
              >
                <v-list-item-action>
                  <v-checkbox
                    :input-value="isSelected(student)"
                    :disabled="isRegistered(student)"
                  />
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ student.givenName }}
                    {{ student.familyName }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ student.schoolName }} | Year
                    {{ student.scholasticYearCode }} | SRN:
                    {{ student.studentRegistrationNumber }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action v-if="isRegistered(student)">
                  <span>Registered</span>
                </v-list-item-action>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>

      <v-col>
        <!-- Right Panel: New Registrants -->
        <v-card>
          <v-card-title>
            <v-row>
              <v-col
                cols="12"
                class="headline"
              >
                New registrants
              </v-col>
              <v-col cols="12">
                <v-card-actions>
                  <span class="subheading mb-2">
                    <strong>{{ availableSpots }}</strong>/{{ event.capacity }} spots available
                  </span>
                  <v-spacer />
                  <AdsButton
                    buttonText="Save and close"
                    @click="saveRegistrations()"
                  />
                </v-card-actions>
              </v-col>
            </v-row>
          </v-card-title>

          <v-divider />

          <v-card-text>
            <v-list style="max-height: 50vh; overflow-y: auto">
              <v-list-item
                v-for="student in newRegistrants"
                :key="student.studentRegistrationNumber"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    {{ student.givenName }}
                    {{ student.familyName }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ student.schoolName }} | Year
                    {{ student.scholasticYearCode }}
                    <span v-if="student.studentRegistrationNumber">| SRN: {{ student.studentRegistrationNumber }}</span>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action @click="removeRegistrant(student)">
                  <v-icon>mdi-close</v-icon>
                </v-list-item-action>
              </v-list-item>

              <v-list-item v-if="newRegistrants.length === 0">
                <v-list-item-content>
                  You haven't added any registrants yet.
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { AdsButton, ChipFilter } from '@nswdoe/doe-ui-core';

export default {
  name: 'StudentRegistration',
  data() {
    return {
      enrolments: [],
      isIndeterminate: false,
      search: '',
      selectedStudentSRNs: [],
      selectedYearLevels: [],
      selectedSchools: [],
      students: [],
      newRegistrants: [],
      existingRegistrations: [],
    };
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  components: {
    AdsButton,
    ChipFilter,
  },
  mounted() {
    this.fetchData();
  },
  watch: {
    'event.schools': function (newSchools, oldSchools) {
      if (newSchools !== oldSchools) {
        this.fetchData();
      }
    }
  },
  computed: {
    selectedStudents() {
      return this.students
        .filter((student) =>
          this.selectedStudentSRNs.includes(student.studentRegistrationNumber)
        )
        .map((student) => ({
          ...student, // Spread operator to include all existing properties of the student
          event_id: this.event.id, // Add the event ID to each student object
        }));
    },
    availableSpots() {
      return this.event.capacity - this.newRegistrants.length;
    },
    isAllSelected() {
      return this.selectedStudents.length === this.filteredStudents.length;
    },
    availableYears() {
      const years = new Set(
        this.students.map((student) => student.scholasticYearCode)
      );
      return Array.from(years)
        .sort()
        .map((year) => {
          return { id: String(year), name: `Year ${year}` };
        });
    },
    availableSchools() {
      const schoolsSet = new Set(
        this.students.map((student) => student.schoolName)
      );
      let id = 0;
      return Array.from(schoolsSet)
        .sort()
        .map((school) => {
          return { id: String(++id), name: school };
        });
    },
    filteredStudents() {
      return this.students
        .filter((student) => {
          const matchesYearLevel =
            !this.selectedYearLevels.length ||
            this.selectedYearLevels.includes(student.scholasticYearCode);
          const matchesSchool =
            !this.selectedSchools.length ||
            this.availableSchools
              .filter((school) => this.selectedSchools.includes(school.id))
              .map((school) => school.name)
              .includes(student.schoolName);

          // Search condition focuses only on student's full name
          const searchLower = this.search.toLowerCase();
          const matchesSearch = (student.givenName + ' ' + student.familyName)
            .toLowerCase()
            .includes(searchLower);

          return matchesYearLevel && matchesSchool && matchesSearch;
        })
        .sort((a, b) => {
          // Sorting logic remains the same
          if (a.schoolName < b.schoolName) return -1;
          if (a.schoolName > b.schoolName) return 1;
          if (a.familyName < b.familyName) return -1;
          if (a.familyName > b.familyName) return 1;
          return 0;
        });
    },
  },
  methods: {
    fetchData() {
      this.$api.registrants.getEnrolments(this.event.schools).then((data) => {
        this.students = data;
      });
      this.$api.registrants
        .getExistingRegistrations(this.event.id)
        .then((response) => {
          this.newRegistrants = response.registrants;
        });
    },
    saveRegistrations() {
      this.$api.registrants
        .save(null, { registrants: this.newRegistrants })
        .then(() => {
          this.$emit('closeAndSave');
          this.newRegistrants = [];
          this.students = [];
        });
    },
    clearAllFilters() {
      this.selectedYearLevels = [];
      this.selectedSchools = [];
      this.search = '';
    },
    removeRegistrant(student) {
      const index = this.newRegistrants.findIndex(
        (registrant) =>
          registrant.studentRegistrationNumber ===
          student.studentRegistrationNumber
      );

      if (index > -1) {
        // Call the API to delete the registrant
        this.$api.registrants
          .deleteRegistrant(student.id)
          .then(() => {
            // Remove the student from newRegistrants only after successful deletion
            this.newRegistrants.splice(index, 1);
          })
          .catch(() => {
            // console.error('Error deleting registrant:', error);
            // Handle the error, maybe inform the user that the deletion failed
          });
      }
    },
    registerSelected() {
      // Calculate the total number of registrants after adding selected students
      const totalRegistrants =
        this.newRegistrants.length + this.selectedStudents.length;

      // Check if the total number of registrants exceeds the event capacity
      if (totalRegistrants > this.event.capacity) {
        // Display an alert or a message to the user
        alert(
          `You are trying to register ${totalRegistrants} students when there are only ${this.availableSpots} spots available. Please either reduce the number of registrations or increase the event capacity from the "Event Details Screen" and try again.`
        );
      } else {
        // If the total does not exceed the capacity, proceed with registration
        this.newRegistrants.push(...this.selectedStudents);

        // Clear the selection after registering
        this.selectedStudentSRNs = [];
      }

      // Update the indeterminate state of the checkbox
      this.updateIndeterminateState();
    },
    toggleSelection(student) {
      if (this.isRegistered(student)) {
        return; // Don't toggle selection if the student is already registered
      }
      const srnIndex = this.selectedStudentSRNs.indexOf(
        student.studentRegistrationNumber
      );
      if (srnIndex >= 0) {
        this.selectedStudentSRNs.splice(srnIndex, 1);
      } else {
        this.selectedStudentSRNs.push(student.studentRegistrationNumber);
      }
      this.updateIndeterminateState();
    },
    toggleAllSelection() {
      if (this.isAllSelected) {
        this.selectedStudentSRNs = [];
      } else {
        this.selectedStudentSRNs = this.filteredStudents.map(
          (student) => student.studentRegistrationNumber
        );
      }
      this.updateIndeterminateState();
    },
    isSelected(student) {
      return this.selectedStudentSRNs.includes(
        student.studentRegistrationNumber
      );
    },
    isRegistered(student) {
      return this.newRegistrants.some(
        (registrant) =>
          registrant.studentRegistrationNumber ===
          student.studentRegistrationNumber
      );
    },
    updateIndeterminateState() {
      this.isIndeterminate =
        this.selectedStudents.length > 0 &&
        this.selectedStudents.length < this.filteredStudents.length;
    },
    selectAll() {
      if (this.selectedStudents.length !== this.students.length) {
        this.selectedStudents = [...this.students];
      } else {
        this.selectedStudents = [];
      }
    },
  },
};
</script>

<style scoped>
.v-dialog .v-card__actions {
  padding: 0 16px 0 16px !important;
}

.greyed-out {
  color: #aaa; /* Grey text */
  background-color: #f5f5f5; /* Light grey background */
}
</style>
