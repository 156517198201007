<template>
  <DoeDialog
    :openDialog="open"
    maxWidth="808px"
    :displayCloseBtn="true"
    :actions="actions"
    @close="open=false"
    :return-handler="dialogReturn"
  >
    <template #title>
      <v-card-title class="pr-14">
        <v-avatar class="mr-3 mb-4">
          <v-icon class="material-icons-outlined">
            {{ icon }}
          </v-icon>
        </v-avatar>
        <div class="title mb-4">
          {{ title }}
        </div>
      </v-card-title>
    </template>
    <template #text>
      <v-card-text>
        <div
          v-if="content"
          class="mb-5"
        >
          <div
            v-for="(text, key) in content"
            :key="key"
          >
            {{ text }}
          </div>
        </div>
      </v-card-text>
    </template>
    <template #actions>
      <v-card-actions>
        <v-btn
          v-for="(action, key) in actions"
          :class="action.className"
          :key="key"
          @click="dialogReturn(action.name)"
          :aria-label="action.name"
          :color="$vuetify.theme.dark ? '#6BACE4' : action.color"
          action.size
          :outlined="action.outlined"
        >
          <v-icon
            v-if="action.icon"
            class="mr-2"
          >
            {{ action.icon }}
          </v-icon>
          {{ action.name }}
        </v-btn>
      </v-card-actions>
    </template>
  </DoeDialog>
</template>

<script>
import { ADS_Colors, Dialog as DoeDialog } from '@nswdoe/doe-ui-core';

export default {
  name: 'EppConfirmationDialog',
  components: {
    DoeDialog
  },
  props: {
    data: {
      type: Object,
      default: undefined
    }
  },
  data() {
    return {
      open: false,
      title: undefined,
      content: undefined,
      confirmation: undefined,
      icon: undefined
    };
  },
  computed: {
    actions() {
      return [
        { name: 'Cancel', color: ADS_Colors.Navy },
        { name: this.confirmation, className: 'border', color: ADS_Colors.Error_Red, outlined: true, icon: this.icon }
      ]
    }
  },
  methods: {
    dialogReturn(action) {
      if (action == 'Cancel') {
        this.open = false;
        return;
      }
      this.open = false;
      if (this.data.callback) {
        this.data.callback();
      }
    }
  },
  watch: {
    data(val) {
      this.open = true
      this.content = Array.isArray(val.content) ? val.content : (val.content ? [val.content] : []);
      ['title', 'confirmation', 'icon'].forEach(k => {
        this[k] = val[k];
      });
    }
  }
};
</script>
