import { defineStore } from 'pinia';

export const useAuthStore = defineStore('auth', {
  state: () => ({
    firstName: undefined,
    lastName: undefined,
    email: undefined,
    role: undefined,
  })
});
