<template>
  <div>
    <EppHeader
      :heading="event.title"
      icon="event"
      :iconOutlined="true"
    >
      <template slot="subtitle">
        <div class="pl-3">
          <div>
            <v-icon
              class="mr-2 mb-1"
              small
            >
              av_timer
            </v-icon>{{ timeRange }}
          </div>
          <div class="mt-1">
            <v-icon
              class="mr-2 mb-1 material-icons-outlined"
              small
            >
              calendar_today
            </v-icon>{{ dateRange }}
            <a
              :href="backend + '/eventICSRetrieve?id=' + event.id"
              class="ml-4 d-print-none"
            >Add to calendar</a>
          </div>
          <div class="mt-1">
            <v-icon
              class="mr-2 mb-1 material-icons-outlined"
              small
            >
              room
            </v-icon><span v-if="event.format == 'Online'">{{ event.access_link }}

              <a
                :href="event.access_link"
                target="_blank"
                class="ml-4 d-print-none"
              >
                Join</a>
            </span>

            <span v-if="event.format == 'In-person'">{{ event.venue_address }}

              <a
                :href="
                  'https://www.google.com/maps/search/' +
                    encodeURIComponent(event.venue_address)
                "
                target="_blank"
                class="ml-4 d-print-none"
              >
                View map</a>
            </span>
          </div>
        </div>
      </template>
      <template slot="rightPannel">
        <div class="d-flex">
          <div
            class="square"
            v-for="(card, key) in cards"
            :key="key"
          >
            <div class="num-container">
              <span class="num-large">{{ card.number }}</span>
              <span class="separator">/</span>
              <span class="num-small">{{ card.total }}</span>
            </div>
            <div class="text-container">
              {{ card.label }}
            </div>
          </div>
        </div>
      </template>
    </EppHeader>
    <v-container v-if="!otherAttendeeForm">
      <v-row>
        <v-col cols="12">
          <!-- <Alert
            class="mb-8"
            showAlert
            type="warning"
            text="Presenter type details missing"
            subtext="This event now requires the presenter type information"
            actionText="Edit event"
            inPage
          /> -->
          <div
            class="d-flex justify-space-between mb-8 flex-column flex-md-row d-print-none"
          >
            <div>
              <h2>1. Student attendance</h2>
            </div>
            <div class="d-flex flex-column flex-md-row mt-2 mt-md-0">
              <AdsButton
                v-if="selected.length > 0"
                class="ml-md-2 mt-2 mt-md-0"
                icon="check"
                buttonText="Mark attended"
                @click="markAttended()"
                :secondary="true"
              />
              <AdsButton
                v-if="selected.length > 0"
                class="ml-md-2 mt-2 mt-md-0"
                icon="close"
                buttonText="Mark unattended"
                @click="markUnttended()"
                :secondary="true"
              />
              <!-- <AdsButton
                v-if="selected.length > 0"
                class="ml-md-2 mt-2 mt-md-0"
                icon="block"
                buttonText="Withdraw"
                @click="withdraw()"
                :secondary="true"
              /> -->
              <AdsButton
                class="ml-md-2 mt-2 mt-md-0"
                icon="person_add"
                buttonText="Add registrants"
                @click="toggleStudentRegistrationDialog()"
              />
              <FullscreenDialog
                title="Add registrants"
                v-model="showStudentRegistrationDialog"
              >
                <template #content>
                  <StudentRegistration
                    v-if="event"
                    :event="event"
                    @closeAndSave="toggleStudentRegistrationDialog"
                  />
                </template>
              </FullscreenDialog>
            </div>
          </div>

          <AdsTextField
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            class="py-4 px-6 search-above-table"
            placeholder="Search"
            label="Search"
            single-line
            hide-details
            clearable
            :outlined="false"
          />

          <v-data-table
            v-model="selected"
            :class="{ 'empty-table': pageCount == 0 }"
            :headers="headers"
            :items="people"
            :search="search"
            :show-select="pageCount != 0"
            no-data-text="There are currently no registrations for this event"
            sort-by="name"
            sort-asc
          >
            <template #[`header.actions`] />
            <!-- <template #[`item.registered`]="{ item }">
              {{ item.registered.slice(10) }}
            </template> -->

            <template #[`item.type`]="{ item }">
              <div style="text-transform: capitalize">
                {{ item.type }}
              </div>
            </template>

            <template #[`item.scholasticYearCode`]="{ item }">
              Year {{ item.scholasticYearCode }}
            </template>

            <template #[`item.created_at`]="{ item }">
              {{ formatDate(item.created_at) }}
            </template>

            <template #[`item.status`]="{ item }">
              <Chip
                :text="item.status"
                :color="statusColour(item.status)"
                style="text-transform: uppercase"
                small
              />
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <AdsButton
            icon="arrow_forward"
            buttonText="Add other attendees"
            @click="toggleOtherAttendeeForm"
            class="d-print-none"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="otherAttendeeForm">
      <v-row>
        <v-col>
          <h2 class="mb-1">
            2. Other attendees
          </h2>
          <p class="mb-2">
            <span v-if="event.requires_registration">Please enter 0 if no attendees of a type attended</span>
            <span v-if="!event.requires_registration">Please enter 1 or more for each type that attended</span>
          </p>
        </v-col>
      </v-row>
      <v-row v-if="otherDataError">
        <v-col>
          <Alert
            showAlert
            type="error"
            text="All fields must have a value of 0 or more"
            inPage
            :backgroundColour="true"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-form ref="form">
            <div v-if="event.requires_registration">
              <v-row>
                <v-col>
                  <AdsTextField
                    label="Number of parents/carers *"
                    v-model="event.parents_carers"
                    :rules="[rules.isZeroOrMore]"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <AdsTextField
                    label="Number of school staff *"
                    v-model="event.school_staff"
                    :rules="[rules.isZeroOrMore]"
                    hint="School staff includes Career advisors, Principals, Teaching staff and Transition Advisors"
                    persistent-hint
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <AdsTextField
                    label="Number of others *"
                    v-model="event.others"
                    :rules="[rules.isZeroOrMore]"
                    hint="Other is any other attendees besides Parents/Carers and School staff"
                    persistent-hint
                  />
                </v-col>
              </v-row>
            </div>
            <div v-if="!event.requires_registration">
              <v-row>
                <v-col>
                  <AdsTextField
                    label="Number of parents/carers *"
                    v-model="event.parents_carers"
                    :rules="[rules.isOneOrMore]"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <AdsTextField
                    label="Number of school staff *"
                    v-model="event.school_staff"
                    :rules="[rules.isOneOrMore]"
                    hint="School staff includes Career advisors, Principals, Teaching staff and Transition Advisors"
                    persistent-hint
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <AdsTextField
                    label="Number of others *"
                    v-model="event.others"
                    :rules="[rules.isOneOrMore]"
                    hint="Other is any other attendees besides Parents/Carers and School staff"
                    persistent-hint
                  />
                </v-col>
              </v-row>
            </div>
            <v-row>
              <v-col>
                <AdsButton
                  icon="arrow_back"
                  buttonText="Previous"
                  @click="toggleOtherAttendeeForm"
                  class="d-print-none mr-2 mt-4"
                  v-if="event.requires_registration"
                />
                <AdsButton
                  buttonText="Submit"
                  @click="saveOtherData"
                  class="d-print-none mt-4"
                />
                <AdsButton
                  secondary
                  buttonText="Cancel"
                  @click="cancel"
                  class="d-print-none mt-4"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { useAuthStore } from '@/stores/auth';
import { parseISO, format } from 'date-fns';

import {
  ADS_Colors,
  AdsButton,
  Alert,
  AdsTextField,
  Chip,
  FullscreenDialog,
} from '@nswdoe/doe-ui-core';

import StudentRegistration from '@/components/StudentRegistration';

export default {
  name: 'EppEventDetail',
  title: 'EPP Initiative Management Application - Event Detail',
  components: {
    AdsButton,
    Alert,
    AdsTextField,
    Chip,
    StudentRegistration,
    FullscreenDialog,
  },
  data() {
    return {
      otherDataError: false,
      showStudentRegistrationDialog: false,
      backend: process.env.VUE_APP_API_BASE_URL,
      people: [],
      ADS_Colors,
      event: {},
      search: '',
      selected: [],
      pageCount: 0,
      otherAttendeeForm: false,
      rules: {
        isOneOrMore(value) {
          // Check if value is not null and not undefined
          if (value !== null && value !== undefined) {
            // Check if the value is a number and greater than or equal to 1
            return typeof value === 'number' && value >= 1;
          }
          // If value is null or undefined, return false
          return 'This field is required and must be a number greater than or equal to 1';
        },
        isZeroOrMore(value) {
          // Check if value is not null and not undefined
          if (value !== null && value !== undefined) {
            // Check if the value is a number and greater than or equal to 0
            return typeof value === 'number' && value >= 0;
          }
          // If value is null or undefined, return false
          return 'This field is required and must be a number greater than or equal to 0';
        },
      },
      headers: [
        {
          text: 'Given Name',
          value: 'givenName',
        },
        {
          text: 'Family Name',
          value: 'familyName',
        },
        {
          text: 'Type',
          value: 'type',
        },
        {
          text: 'SRN',
          value: 'studentRegistrationNumber',
        },
        // {
        //   text: 'School Group',
        //   value: 'region',
        // },
        {
          text: 'School',
          value: 'schoolName',
        },
        {
          text: 'Year',
          value: 'scholasticYearCode',
        },
        {
          text: 'Registered',
          value: 'created_at',
        },
        {
          text: 'Status',
          value: 'status',
        },
        {
          value: 'actions',
          sortable: false,
        },
      ],
    };
  },
  computed: {
    statusColour() {
      return (status) => {
        if (status === 'registered') {
          return '#407EC9';
        }
        if (status === 'attended') {
          return '#071E42';
        }
      };
    },
    loggedInUserRole() {
      const auth = useAuthStore();
      return auth.role;
    },
    admin() {
      return ['Super Admin', 'Event Admin', 'Student Admin'].includes(
        this.loggedInUserRole
      );
    },
    timeRange() {
      if (!this.event.start_datetime || !this.event.end_datetime) {
        return ''; // Return an empty string or some default value
      }

      try {
        const startTime = format(parseISO(this.event.start_datetime), 'h:mm a');
        const endTime = format(parseISO(this.event.end_datetime), 'h:mm a');
        return `${startTime} - ${endTime}`;
      } catch (error) {
        return ''; // Return an empty string or some default value in case of error
      }
    },
    dateRange() {
      if (!this.event.start_datetime || !this.event.end_datetime) {
        return ''; // Return an empty string or some default value
      }

      try {
        const startDate = format(
          parseISO(this.event.start_datetime),
          'EEE d MMM, yyyy'
        );
        const endDate = format(
          parseISO(this.event.end_datetime),
          'EEE d MMM, yyyy'
        );

        let dateRange;
        if (startDate === endDate) {
          dateRange = startDate;
        } else {
          dateRange = `${startDate} - ${endDate}`;
        }

        return dateRange;
      } catch (error) {
        return ''; // Return an empty string or some default value in case of error
      }
    },
    cards() {
      return [
        {
          number: this.event.number_of_registrations,
          total: this.event.capacity,
          label: 'Registrations',
        },
        {
          number: this.event.number_of_attendees,
          total: this.event.number_of_registrations,
          label: 'Attended',
        },
      ];
    },
  },
  mounted() {
    this.fetchEventData(this.$route.params.id);
    this.fetchRegistrants(this.$route.params.id);
  },
  methods: {
    isNumberGreaterOrEqualThanZero(value) {
      return (
        value !== null &&
        value !== undefined &&
        !isNaN(value) &&
        parseFloat(value) >= 0
      );
    },
    isNumberGreaterThanZero(value) {
      return (
        value !== null &&
        value !== undefined &&
        !isNaN(value) &&
        parseFloat(value) >= 0
      );
    },
    saveOtherData() {
      // console.log(this.event.parents_carers);
      // console.log(this.event.school_staff);
      // console.log(this.event.others);

      if (
        (this.event.requires_registration &&
          this.isNumberGreaterOrEqualThanZero(this.event.parents_carers) &&
          this.isNumberGreaterOrEqualThanZero(this.event.school_staff) &&
          this.isNumberGreaterOrEqualThanZero(this.event.others)) ||
        (!this.event.requires_registration &&
          this.isNumberGreaterThanZero(this.event.parents_carers) &&
          this.isNumberGreaterThanZero(this.event.school_staff) &&
          this.isNumberGreaterThanZero(this.event.others))
      ) {
        // console.log('All fields have valid values');
        // If all fields have valid values, proceed with submission
        this.$api.events
          .saveOtherAttendeeData(this.$route.params.id, {
            parents_carers: this.event.parents_carers,
            school_staff: this.event.school_staff,
            others: this.event.others,
          })
          .then(() => {
            this.fetchEventData(this.$route.params.id);
            this.fetchRegistrants(this.$route.params.id);
            this.toggleOtherAttendeeForm();
            this.$router.push('/');
          });
      } else {
        this.otherDataError = true;
      }
    },
    cancel() {
      this.$router.push('/');
    },
    toggleOtherAttendeeForm() {
      this.otherAttendeeForm = !this.otherAttendeeForm;
    },
    toggleStudentRegistrationDialog() {
      this.showStudentRegistrationDialog = !this.showStudentRegistrationDialog;
      if (!this.showStudentRegistrationDialog) {
        this.fetchEventData(this.$route.params.id);
        this.fetchRegistrants(this.$route.params.id);
      }
    },
    withdraw() {
      this.$api.registrants.withdraw(this.selected).then(() => {
        this.fetchEventData(this.$route.params.id);
        this.fetchRegistrants(this.$route.params.id);
      });
    },
    markAttended() {
      this.$api.registrants
        .markAttendance(this.selected, 'attended')
        .then(() => {
          this.fetchEventData(this.$route.params.id);
          this.fetchRegistrants(this.$route.params.id);
        });
    },
    markUnttended() {
      this.$api.registrants
        .markAttendance(this.selected, 'unattended')
        .then(() => {
          this.fetchEventData(this.$route.params.id);
          this.fetchRegistrants(this.$route.params.id);
        });
    },
    formatDate(timestamp) {
      // Convert the timestamp to a Date object
      const date = new Date(timestamp);

      // Format the date as dd/MM/yyyy
      return format(date, 'dd/MM/yyyy');
    },
    goHome() {
      this.$router.push({ name: 'events' });
    },
    fetchEventData(id) {
      this.$api.events.fetch(id).then((response) => {
        this.event = response.events[0];
      });
    },
    fetchRegistrants(id) {
      this.$api.registrants.getExistingRegistrations(id).then((response) => {
        this.people = response.registrants;
        this.pageCount = Math.ceil(this.people.length / 10);
      });
    },
  },
  watch: {
    '$route.params.id': function (newId, oldId) {
      if (newId !== oldId) {
        this.fetchEventData(newId);
      }
    },
    'event.requires_registration'(newStatus) {
      if (!newStatus) {
        this.otherAttendeeForm = true;
      } else {
        this.otherAttendeeForm = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.square {
  background: rgba(64, 126, 201, 0.3);
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center the content vertically */
  align-items: center; /* Center the content horizontally */
  width: 162px; /* Adjust based on your desired size */
  height: 128px;
  margin-left: 10px;
  border-radius: 5px;
}

.num-container {
  display: flex;
  align-items: baseline; /* This will ensure the numbers align to the baseline */
}

.num-large {
  font-size: 40px;
}

.separator {
  font-size: 14px; /* Same size as the larger number so it aligns nicely */
}

.num-small {
  font-size: 14px;
}

.text-container {
  font-size: 14px; /* Adjust based on your preference */
  color: rgba(108, 172, 228, 1);
  font-weight: bold;
}
</style>
