import { get, post } from '../http'

const fetch = () => get('userRetrieve')
const fetchAll = () => get('usersRetrieve')

const save = (id, data) => {
    let url = 'userCreate';
    if (id) {
      url += '?id=' + id;
    }
    return post(url, data);
  };

export default {
    fetch,
    fetchAll,
    save
}
