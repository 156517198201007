import {get, post, del} from '../http';

const fetch = id => {
  let url = 'notificationsRetrieve'
  if (id) {
    url += '?id='+id;
  }
  return get(url);
}
const markAsRead = () => post('notificationsMarkAllAsRead');
const remove = id => del('notificationsDelete?id='+id);

export default {
  fetch,
  markAsRead,
  remove
};
