<template>
  <div>
    <v-tabs
      v-bind="$attrs"
      v-on="$listeners"
      v-model="tab"
      next-icon="keyboard_arrow_right"
      prev-icon="keyboard_arrow_left"
      :show-arrows="true"
      class="v-tab-navigation"
    >
      <v-tab
        v-for="(item, tabIndex) in items"
        :key="item.tab"
        :data-testid="$attrs['data-testid'] ? $attrs['data-testid'] + tabIndex : `tab${tabIndex}`"
      >
        <span v-html="item.tab" />
      </v-tab>
      <slot name="extra" />
    </v-tabs>
    <v-tabs-items
      v-model="tab"
      class="v-tab-navigation"
      style="min-height: 100vh;"
    >
      <v-tab-item
        v-for="(item, i) in items"
        :key="item.tab"
      >
        <slot :name="'content'+ (item.id ? item.id : i + 1)" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import {useNavStore} from '@/stores/nav';

export default {
  name: 'TabNavigation',
  data() {
    const nav = useNavStore();
    return {
      tab: nav.type
    };
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    }
  },
  watch: {
    tab(type) {
      const nav = useNavStore();
      nav.$patch({
        type
      });
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../scss/variables";

.v-tab-navigation .v-slide-group__wrapper,
.v-tab-navigation .v-window__container {
  padding-left: 0 !important;
}

.v-tabs .v-tab {
  font-size: 14px;
  text-align: left;
  &.v-tab--active {
    font-weight: bold;
  }
}

.theme--light.v-tabs .v-tab:focus::before {
  opacity: inherit;
  border: 4px solid $ads-dark-60 !important;
}

.theme--light.v-tabs .v-tab.v-tab--active:focus::before {
  opacity: inherit;
  border: 4px solid $ads-navy !important;
}

.theme--dark.v-tabs .v-tab:focus::before {
  opacity: inherit;
  border: 4px solid $ads-white !important;
}

.v-tab:before {
  background-color: inherit !important;
}
</style>
