import { defineStore } from 'pinia'

export const useAudiencesStore = defineStore('audiences', {
  state: () => ({
    items: [
        'Career advisors (including transition advisors)',
        'Employer and industry',
        'Head teacher careers',
        'High school students',
        'Parents and carers',
        'Principals',
        'Teaching staff',
        'Other'
      ],
  })
})