import axios from 'axios';
import qs from 'qs';
import Vue from 'vue';

/**
 * Create an axios instance and configure THIS instance.
 * If directly import axios and use, settings here won't take effect.
 * Default headers is 'Content-Type': 'application/json'
 */
const requestConfig = {
  // API base URL is configurable and set up in .env.* files
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 60000,
}
const axiosInstance = axios.create(requestConfig);

axiosInstance.interceptors.request.use(config => {
  // Prevent potential infinite loop by not re-refreshing authorisation
  // immediately after returning from being authorised
  if (!Vue.prototype.$OAuth.isAuthenticated() && window.location.pathname != '/login/callback') {
    // We don't have auth, need to authenticate.
    localStorage.setItem('eppURL', window.location.pathname);
    Vue.prototype.$OAuth.getAuth();
  }

  config.headers = Vue.prototype.$OAuth.buildHttpHeader(config.headers);
  return config;
});

axiosInstance.interceptors.response.use(response => response && response.data);

export function setupHttp(errorHandler) {
  if (Vue.prototype.$OAuth.isAuthenticated()) {
    axiosInstance.defaults.headers.common['Authorization'] = localStorage.getItem('token_type')+' '+localStorage.getItem('access_token');
  }

  axiosInstance.interceptors.response.use(response => response, error => {
    errorHandler(error);
    return Promise.reject(error);
  });
}

/**
 * GET method
 * @param url
 * @param config
 * @returns {Promise}
 */
export function get(url, config = {}) {
  return axiosInstance.get(url, config);
}

/**
 * POST method
 * by default, axios uses JSON format - e.g. 'Content-Type': 'application/json'
 * @param url
 * @param data
 * @param config
 * @returns {Promise}
 * @param isForm determines headers['Content-Type']
 */
export function post(url, data = {}, config = {}, isForm = false) {
  if (isForm) {
    axiosInstance.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded';
    axiosInstance.defaults.transformRequest = data => qs.stringify(data);
  } else { // axios default
    axiosInstance.defaults.headers['Content-Type'] = 'application/json';
    axiosInstance.defaults.transformRequest = undefined;
  }
  return axiosInstance.post(url, JSON.stringify(data), config);
}

/**
 * PUT method
 * by default, axios uses JSON format - e.g. 'Content-Type': 'application/json'
 * @param url
 * @param data
 * @param config
 * @returns {Promise}
 * @param isForm determines headers['Content-Type']
 */
export function put(url, data = {}, config = {}, isForm = false) {
  if (isForm) {
    axiosInstance.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded';
    axiosInstance.defaults.transformRequest = data => qs.stringify(data);
  } else { // axios default
    axiosInstance.defaults.headers['Content-Type'] = 'application/json';
    axiosInstance.defaults.transformRequest = undefined;
  }
  return axiosInstance.put(url, JSON.stringify(data), config);
}

export function del(url, data = {}, config = {}, isForm = false) {
  if (isForm) {
    axiosInstance.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded';
    axiosInstance.defaults.transformRequest = data => qs.stringify(data);
  } else { // axios default
    axiosInstance.defaults.headers['Content-Type'] = 'application/json';
    axiosInstance.defaults.transformRequest = undefined;
  }
  return axiosInstance.delete(url, JSON.stringify(data), config);
}


// Can add JWT token here for each request
/*axiosInstance.interceptors.request.use(
  function () {
  },
  function () {
  }
);*/

// Can check HTTP response code here and jump to different page (e.g. Offline Page)
/*axiosInstance.interceptors.request.use(
  function () {
  },
  function () {
  }
);*/

export default axiosInstance;