import {get, post} from '../http';

const fetch = id => get('staffRetrieve?id='+id);
const fetchForSession = id => get('staffRetrieveFromSessionSchools?id='+id);
const search = (staffName) => post('staffSearch', {name: staffName});

export default {
  fetch,
  fetchForSession,
  search
};
