import { defineStore } from 'pinia'

export const usePresenterTypesStore = defineStore('presenterTypes', {
  state: () => ({
    items: [
        'Employer',
        'GTO',
        'HTC',
        'Job Readiness Provider',
        'RIEP',
        'RTO',
        'SBATEO',
        'VET Ambassadors',
        'Other'
      ],
  })
})