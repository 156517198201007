import { defineStore } from 'pinia';

export const useActivityTypesStore = defineStore('activityTypes', {
  state: () => ({
    items: [
      'Conference',
      'Course',
      'Career Expos',
      'Industry Immersion',
      'Meeting',
      'Mock Interviews',
      'Presentation Session',
      'Training',
      'Webinar',
      'Workshop',
      'Other',
    ],
  }),
});
