import { defineStore } from 'pinia';

export const useInitiativeTypesStore = defineStore('initiativeTypes', {
  state: () => ({
    items: [
      'Apprenticeship and Traineeship Headstart',
      'Innovating Careers Education',
      'Job Readiness',
      'VET Ambassadors',
    ],
  }),
});
