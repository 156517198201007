import { defineStore } from 'pinia';

export const useCategoriesStore = defineStore('categories', {
  state: () => ({
    items: [
      'Apprenticeship and Traineeship Headstart',
      'CIT Meeting',
      'Executive Presentation',
      'Job Readiness Workshop',
      'Professional Learning',
      'Student / School Event or Activity',
      'Updates to School Teams',
      'VET Ambassador Event',
    ],
  }),
});
