<template>
  <EppDialog
    :openDialog="open"
    maxWidth="808px"
    :displayCloseBtn="true"
    :actions="actions"
    @close="open=false"
    :return-handler="dialogReturn"
  >
    <template #title>
      <v-card-title class="pr-14">
        <v-avatar class="mr-3 mb-4 error">
          <v-icon>
            error_outline
          </v-icon>
        </v-avatar>
        <div class="title mb-4">
          Error
        </div>
      </v-card-title>
    </template>
    <template #text>
      <v-card-text>
        <div
          v-for="(error, i) in errors"
          :key="i"
        >
          {{ error }}
        </div>
      </v-card-text>
    </template>
  </EppDialog>
</template>

<script>
import { ADS_Colors, Dialog as EppDialog } from '@nswdoe/doe-ui-core';

export default {
  name: 'EppErrorDialog',
  components: {
    EppDialog
  },
  props: {
    data: {
      type: Object,
      default: undefined
    }
  },
  data() {
    return {
      open: false,
      errors: undefined,
      actions: [
        { name: 'Dismiss', color: ADS_Colors.Navy, outlined: true }
      ]
    }
  },
  methods: {
    dialogReturn() {
      this.open = false;
    }
  },
  watch: {
    data(val) {
      if (val) {
        this.open = true;
        ['errors'].forEach(k => {
          this[k] = val[k];
        });
      } else {
        this.open = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../scss/variables";

.v-avatar .v-icon {
  color: $ads-white;
}

.v-card__text {
  white-space: pre-line;
}
</style>
