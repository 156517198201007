<template>
  <div>
    <v-menu
      v-if="$vuetify.breakpoint.smAndUp"
      v-model="show"
      offset-y
      bottom
      attach
      transition="scale-transition"
      :left="alignRight"
      :nudge-bottom="8"
      :origin="alignRight ? 'top right' : 'top left'"
      :min-width="minWidth"
      :max-height="maxHeight"
    >
      <template
        #activator="{ on, attrs }"
      >
        <Activator
          v-on="on"
          v-bind="attrs"
          v-model="show"
          :selected="selected"
          :name="name"
          :heading="heading"
          :id="chipId"
          @clear:filter="clearFilter"
        />
      </template>
      <EppContent
        :heading="heading"
        v-model="dates"
        @close:menu="show = false"
      />
    </v-menu>
    <!--    Mobile version-->
    <v-dialog
      v-if="$vuetify.breakpoint.xsOnly"
      v-model="show"
    >
      <template
        #activator="{ on, attrs }"
      >
        <Activator
          v-on="on"
          v-bind="attrs"
          v-model="show"
          :selected="selected"
          :name="name"
          :heading="heading"
          :id="chipId"
          @clear:filter="clearFilter"
        />
      </template>
      <EppContent
        :heading="heading"
        v-model="dates"
      />
    </v-dialog>
  </div>
</template>

<script>
import { ChipFilter } from '@nswdoe/doe-ui-core';
import EppContent from './Content';

// ChipFilter's v-model get selected values from the checkbox or radio group
export default {
  name: 'EppDateChipFilter',
  inheritAttrs: false,
  components: {
    Activator: ChipFilter.components.Activator,
    EppContent
  },
  props: {
    name: {
      type: String,
      default: ''
    },
    heading: {
      type: String,
      default: 'Show only'
    },
    items: {
      type: Array,
      default: () => []
    },
    alignRight: {
      type: Boolean,
      default: false
    },
    minWidth: {
      type: Number,
      default: 280
    },
    maxHeight: {
      type: Number,
      default: 480
    },
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      // boolean v-model controlling if Content is visible or hidden.
      show: false,
      dates: this.value
    };
  },
  methods: {
    clearFilter() {
      this.dates = undefined;
    }
  },
  computed: {
    selected() {
      return this.value ? true : false;
    },
    chipId() {
      return 'chip-' +
        this.name.replace(/[^a-zA-Z ]/g, '')
          .split(' ')
          .join('')
          .toLowerCase();
    }
  },
  watch: {
    dates(val) {
      this.$emit('input', val);
    },
    value(val) {
      this.dates = val;
    }
  }
}
</script>

<style lang="scss" scoped>
// fix issue with menu not displaying after adding 'attach' attribute
.v-menu {
  display: block;
}

// dialog open at the top, full screen width on mobile portrait
.v-dialog__content:deep {
  align-items: start;

  &.v-dialog__content--active {
    .v-dialog {
      &.v-dialog--active {
        margin: 0;
        border-radius: 0;
      }
    }
  }
}
</style>
