<template>
  <v-navigation-drawer
    v-bind="$attrs"
    v-on="$listeners"
    v-model="drawer"
    :app="true"
    :right="true"
    :clipped="true"
    :width="360"
    :color="$vuetify.theme.dark ? ADS_Colors.DP02 : ADS_Colors.Light_10"
    :temporary="true"
    tabindex="0"
    ref="navRoot"
    style="top: 64px"
  >
    <div class="notifications">
      <v-card
        flat
        v-if="notifications.length > 0"
      >
        <v-card-title
          class="d-flex contrast notificationTitle justify-space-between py-4 pr-1"
          :style="{backgroundColor: $vuetify.theme.dark ? ADS_Colors.DP02 : ADS_Colors.Navy}"
        >
          <span
            role="heading"
            tabindex="0"
            :aria-label="notificationData.length + 'notification(s)'"
            class="title"
            :style="{color: ADS_Colors.White}"
          >
            Notifications
            <span role="title">
              <v-badge
                :content="notificationData.length"
                color="red"
                class="ml-2"
              />
            </span>
          </span>
          <span
            role="button"
            class="pr-1"
          >
            <v-btn
              aria-label="close notifications"
              @click="$eventHub.$emit('toggle:notifications')"
              @keyup.enter="$eventHub.$emit('toggle:notifications')"
              size="18"
              class="size--40"
              icon
            >
              <v-icon
                size="24"
                :color="ADS_Colors.White"
              >
                close
              </v-icon>
            </v-btn>
          </span>
        </v-card-title>

        <v-divider class="divider" />

        <v-card-text class="pa-0">
          <v-list
            class="notification--contents contrast"
            flat
            style="padding-bottom: 115px"
            three-line
          >
            <template v-for="(item, index) in notifications">
              <v-list-item
                class="pl-5 pr-2 py-0"
                role="row"
                :key="'notification' + index"
                :aria-label="'notification item ' + (index + 1)"
                :style="index < notifications.length - 1 ? 'border-bottom:1px solid #A0A5AE': ''"
              >
                <template #default>
                  <v-list-item-icon class="mr-4">
                    <div
                      class="d-flex align-self-center"
                      style="height:36px;"
                    >
                      <div
                        class="align-self-center d-flex"
                        style="height: 40px; width: 40px; border-radius: 4px"
                      >
                        <v-icon
                          size="30"
                          class="ma-auto material-icons-outlined"
                          :color="ADS_Colors.Navy"
                        >
                          notifications
                        </v-icon>
                      </div>
                    </div>
                  </v-list-item-icon>

                  <v-list-item-content
                    tabindex="0"
                    :aria-label="item.title + ' ' + item.content + ' ' + timeSince(item.created_at) + ' ago'"
                  >
                    <div
                      class="subtitle-1 mb-2"
                      v-text="showTruncated(item.title, 39)"
                      :title="item.title"
                      tabindex="0"
                    />
                    <div
                      class="pb-2 body-2 pr-2"
                      tabindex="0"
                      :title="item.content"
                    >
                      <div v-html="item.content" /><div
                        v-if="item.link_href && item.link_text"
                        class="pt-2"
                      >
                        <a
                          :target="item.link_href.match(/(https?:)?\/\//) ? '_blank' : ''"
                          :href="item.link_href"
                          class="font-weight-bold text-decoration-none"
                        >
                          {{ item.link_text }}
                        </a>
                      </div>
                    </div>

                    <div class="d-flex pb-0 mb-0">
                      <div class="d-flex grow align-self-center justify-space-between">
                        <span
                          class="caption align-self-center text-color"
                          style="max-width: 90%"
                        >
                          {{ timeSince(item.created_at) + ' ago' }}
                        </span>
                      </div>
                    </div>
                  </v-list-item-content>

                  <v-list-item-icon class="ml-0">
                    <span class="mt-n1">
                      <v-btn
                        :aria-label="'delete notification ' + item.title"
                        @click="$eventHub.$emit('delete:notification', item.id)"
                        style="width: 40px; height: 40px"
                        class="pa-0"
                        icon
                      >
                        <v-icon
                          size="20"
                          :color="ADS_Colors.Dark_60"
                        >
                          close
                        </v-icon>
                      </v-btn>
                    </span>
                  </v-list-item-icon>
                </template>
              </v-list-item>
            </template>
          </v-list>
        </v-card-text>
      </v-card>
      <v-card
        flat
        v-if="notifications.length == 0"
        style="text-align: center"
        class="flex py-10"
      >
        <img
          alt="no notification image"
          src="../../../node_modules/@nswdoe/doe-ui-core/src/Notifications/no_notification.svg"
        >
        <div
          role="heading"
          class="noItem"
        >
          No notifications
        </div>
      </v-card>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { ADS_Colors, Notifications } from '@nswdoe/doe-ui-core';
import orderBy from 'lodash/orderBy';

export default {
  name: 'EppNotifications',
  props: {
    notificationData: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data() {
    return {
      drawer: false,
      ADS_Colors
    }
  },
  mounted() {
    this.$eventHub.$on('toggle:notifications', this.toggleNotifications);
  },
  beforeDestroy() {
    this.$eventHub.$off('toggle:notifications');
  },
  computed: {
    notifications() {
      return orderBy(this.notificationData, item => item.created_at, 'desc')
    }
  },
  methods: {
    toggleNotifications() {
      this.drawer = !this.drawer;
      this.$refs.navRoot.$el.focus();
    },
    showTruncated: Notifications.methods.showTruncated,
    timeSince: Notifications.methods.timeSince
  }
}
</script>

<style lang="scss" scoped>
.notifications {
  overflow: hidden;

  .divider {
    top: 70px;
    z-index: 1000;
    position: fixed !important;
    overflow: hidden;
    width: 100%;
    opacity: 1;
  }

  .notificationTitle {
    position: fixed !important;
    overflow: hidden;
    width: 100%;
    z-index: 1000;
    opacity: 1;
  }

  .notification--contents {
    top: 70px;
    position: relative;
    max-height: 100vh;
    overflow: scroll;
  }

  .size--40 {
    width: 40px;
    height: 40px
  }

  .v-sheet {
    border-radius: 0;
  }
}

.caption {
  font-size: 14px !important;
  font-weight: 400;
  line-height: 1rem;
}

.text-color {
  color: $ads-dark-70;
}
</style>
