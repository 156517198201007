<template>
  <v-container>
    <v-form ref="editEventForm">
      <v-stepper
        v-model="editEventStep"
        vertical
      >
        <v-stepper-step
          :complete="editEventStep > 1"
          step="1"
        >
          Event Details
        </v-stepper-step>

        <v-stepper-content step="1">
          <v-row>
            <v-col cols="12" />
          </v-row>
          <v-row>
            <v-col cols="12">
              <AdsTextField
                v-model="editEvent.title"
                label="Event title *"
                placeholder="Enter event title"
                :rules="[validation.required]"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <AdsSelect
                v-model="editEvent.initiative_type"
                label="Initiative type *"
                :items="initiativeTypes"
                placeholder="Please select"
                :rules="[validation.required]"
              />
            </v-col>
          </v-row>

          <v-switch
            v-if="
              editEvent.initiative_type == 'Innovating Careers Education' ||
                editEvent.initiative_type == 'Job Readiness'
            "
            style="margin-top: 0"
            v-model="editEvent.combined_event"
            label="VET Ambassador combined event"
          />

          <v-row>
            <v-col cols="12">
              <AdsSelect
                v-model="editEvent.category"
                label="Category *"
                :items="filteredCategories"
                placeholder="Please select"
                :rules="[validation.required]"
                :hint="registrationRequiredMessage"
                persistent-hint
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <AdsSelect
                v-model="editEvent.activity_type"
                label="Activity type *"
                :items="filteredActivities"
                :rules="[validation.required]"
                placeholder="Please select"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <AdsTextArea
                v-model="editEvent.description"
                label="Description *"
                :rules="[validation.required]"
                placeholder="Enter event description"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <AdsSelect
                v-model="editEvent.presenter_type"
                label="Presenter type"
                hint="If unknown, this can be completed later"
                persistent-hint
                :items="presenterTypes"
                placeholder="Please select"
              />
            </v-col>
          </v-row>

          <v-row v-if="editEvent.presenter_type == 'Employer'">
            <v-col cols="12">
              <AdsTextField
                v-model="editEvent.presenter_number"
                label="Number of employers"
                hint="If unknown, this can be completed later"
                persistent-hint
                :rules="[validation.number]"
              />
            </v-col>
          </v-row>
          <v-row v-if="editEvent.presenter_type == 'Employer'">
            <v-col cols="12">
              <AdsTextField
                v-model="editEvent.presenter_name"
                label="Employer name"
                hint="If unknown, this can be completed later"
                persistent-hint
              />
            </v-col>
          </v-row>
          <v-row v-if="editEvent.presenter_type == 'GTO'">
            <v-col cols="12">
              <AdsTextField
                v-model="editEvent.presenter_name"
                label="Name of GTO"
                hint="If unknown, this can be completed later"
                persistent-hint
              />
            </v-col>
          </v-row>
          <v-row v-if="editEvent.presenter_type == 'HTC'">
            <v-col cols="12">
              <AdsTextField
                v-model="editEvent.presenter_number"
                label="Number of HTCs"
                hint="If unknown, this can be completed later"
                persistent-hint
                :rules="[validation.number]"
              />
            </v-col>
          </v-row>
          <v-row v-if="editEvent.presenter_type == 'HTC'">
            <v-col cols="12">
              <AdsTextField
                v-model="editEvent.presenter_name"
                label="Name of HTC"
                hint="If unknown, this can be completed later"
                persistent-hint
              />
            </v-col>
          </v-row>
          <v-row v-if="editEvent.presenter_type == 'Job Readiness Provider'">
            <v-col cols="12">
              <AdsTextField
                v-model="editEvent.presenter_number"
                label="Number of presenters from job readiness"
                hint="If unknown, this can be completed later"
                persistent-hint
                :rules="[validation.number]"
              />
            </v-col>
          </v-row>
          <v-row v-if="editEvent.presenter_type == 'RIEP'">
            <v-col cols="12">
              <AdsTextField
                v-model="editEvent.presenter_number"
                label="Number of presenters from RIEP"
                :rules="[validation.number]"
                hint="If unknown, this can be completed later"
                persistent-hint
              />
            </v-col>
          </v-row>
          <v-row v-if="editEvent.presenter_type == 'RTO'">
            <v-col cols="12">
              <AdsTextField
                v-model="editEvent.presenter_name"
                label="Name of RTO"
                hint="If unknown, this can be completed later"
                persistent-hint
              />
            </v-col>
          </v-row>
          <v-row v-if="editEvent.presenter_type == 'SBATEO'">
            <v-col cols="12">
              <AdsTextField
                v-model="editEvent.presenter_number"
                label="Number of SBATEOs"
                :rules="[validation.number]"
                hint="If unknown, this can be completed later"
                persistent-hint
              />
            </v-col>
          </v-row>
          <v-row v-if="editEvent.presenter_type == 'SBATEO'">
            <v-col cols="12">
              <AdsTextField
                v-model="editEvent.presenter_name"
                label="SBATEO names"
                hint="If unknown, this can be completed later"
                persistent-hint
              />
            </v-col>
          </v-row>
          <v-row v-if="editEvent.presenter_type == 'VET Ambassadors'">
            <v-col cols="12">
              <AdsTextField
                v-model="editEvent.presenter_number"
                label="Number of VET Ambassadors"
                :rules="[validation.number]"
                hint="If unknown, this can be completed later"
                persistent-hint
              />
            </v-col>
          </v-row>
          <v-row v-if="editEvent.presenter_type == 'VET Ambassadors'">
            <v-col cols="12">
              <AdsTextField
                v-model="editEvent.presenter_name"
                label="VET Ambassador names"
                hint="If unknown, this can be completed later"
                persistent-hint
              />
            </v-col>
          </v-row>
          <v-row v-if="editEvent.presenter_type == 'Other'">
            <v-col cols="12">
              <AdsTextField
                v-model="editEvent.presenter_other"
                label="Please specify"
                hint="If unknown, this can be completed later"
                persistent-hint
              />
            </v-col>
          </v-row>

          <Alert
            v-if="showStepError"
            showAlert
            type="error"
            text="Please complete all required fields indicated by a *"
            inPage
            :backgroundColour="true"
          />
          <v-row>
            <v-col cols="12">
              <v-btn
                color="primary"
                @click="moveToStep(2)"
              >
                Continue
              </v-btn>
              <v-btn
                text
                @click="toggleDialog()"
              >
                Cancel
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>

        <v-stepper-step
          :complete="editEventStep > 2"
          step="2"
        >
          Scheduling
        </v-stepper-step>

        <v-stepper-content step="2">
          <v-row>
            <v-col cols="12">
              <p><strong>Date and time</strong></p>
            </v-col>
            <v-col cols="6">
              <DatePicker
                v-model="editEvent.start_date"
                label="Start date *"
                id="start_date"
                :rules="[validation.required]"
              />
            </v-col>
            <v-col cols="6">
              <DatePicker
                v-model="editEvent.end_date"
                label="End date *"
                id="end_date"
                :rules="[validation.required]"
                :datePickerAttrs="{
                  min: editEvent.start_date,
                }"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <v-dialog
                ref="startTimeDialog"
                v-model="startTimeDialog"
                width="290px"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="editEvent.start_time"
                    label="Start time *"
                    prepend-inner-icon="mdi-clock-time-four-outline"
                    outlined
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-time-picker
                  v-model="editEvent.start_time"
                  full-width
                  ampm-in-title
                  format="ampm"
                  :rules="[validation.required]"
                />
              </v-dialog>
            </v-col>
            <v-col cols="6">
              <v-dialog
                ref="endTimeDialog"
                v-model="endTimeDialog"
                width="290px"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="editEvent.end_time"
                    label="End time *"
                    prepend-inner-icon="mdi-clock-time-four-outline"
                    outlined
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-time-picker
                  v-model="editEvent.end_time"
                  full-width
                  ampm-in-title
                  format="ampm"
                  :rules="[validation.required]"
                />
              </v-dialog>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <AdsSelect
                v-model="editEvent.term"
                label="Term *"
                :items="terms"
                placeholder="Please select"
                :rules="[validation.required]"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <p><strong>Location</strong></p>
            </v-col>

            <v-col cols="12">
              <div style="font-weight: bold">
                Delivery *
              </div>
              <v-radio-group
                v-model="editEvent.format"
                row
              >
                <v-radio
                  v-for="(format, index) in formats"
                  :label="format"
                  :value="format"
                  :key="index"
                />
              </v-radio-group>
            </v-col>

            <v-col>
              <AdsTextField
                v-model="editEvent.venue_address"
                label="Enter a location *"
                :rules="[validation.required]"
                placeholder="Type address"
                v-if="editEvent.format == 'In-person'"
                ref="addressInput"
              />

              <AdsTextField
                v-model="editEvent.access_link"
                label="Access link *"
                :rules="[validation.required, validation.url]"
                placeholder="Type URL"
                v-if="editEvent.format == 'Online'"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <AdsTextField
                v-model="editEvent.capacity"
                label="Capacity *"
                placeholder="Please enter a number"
                :rules="[validation.number]"
              />
            </v-col>
          </v-row>

          <Alert
            v-if="showStepError"
            showAlert
            type="error"
            text="Please complete all required fields indicated by a *"
            inPage
            :backgroundColour="true"
          />
          <v-row>
            <v-col cols="12">
              <v-btn
                color="primary"
                @click="moveToStep(3)"
              >
                Continue
              </v-btn>
              <v-btn
                text
                @click="editEventStep = 1"
              >
                Previous
              </v-btn>
              <v-btn
                text
                @click="toggleDialog()"
              >
                Cancel
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>

        <v-stepper-step
          :complete="editEventStep > 3"
          step="3"
        >
          Participation
        </v-stepper-step>

        <v-stepper-content step="3">
          <v-row>
            <v-col cols="12">
              <p><strong>School type *</strong></p>

              <v-radio-group
                v-model="editEvent.school_type"
                row
              >
                <v-radio
                  label="EPP school"
                  value="EPP school"
                />
                <v-radio
                  label="Flexible delivery school"
                  value="Flexible delivery school"
                />
              </v-radio-group>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <AdsSelect
                v-model="editEvent.selected_region_ids"
                label="Region *"
                :items="regions"
                placeholder="Please select"
                item-text="name"
                item-value="id"
                :rules="[validation.required]"
                multiple
                chips
              />
            </v-col>

            <v-col cols="6">
              <AdsSelect
                v-model="editEvent.selected_group_ids"
                label="School group *"
                :items="filteredSchoolGroups"
                placeholder="Please select"
                item-text="name"
                item-value="id"
                :rules="[validation.required]"
                multiple
                chips
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <AdsSelect
                v-model="editEvent.schools"
                label="Schools *"
                :items="filteredSchools"
                :rules="[validation.required]"
                placeholder="Please select"
                item-text="name"
                item-value="id"
                multiple
                chips
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <AdsSelect
                v-model="editEvent.audiences"
                label="Audiences *"
                :items="filteredAudiences"
                multiple
                chips
                :rules="[validation.required]"
                placeholder="Please select"
              />
            </v-col>
          </v-row>

          <Alert
            v-if="showStepError"
            showAlert
            type="error"
            text="Please complete all required fields indicated by a *"
            inPage
            :backgroundColour="true"
          />

          <v-row>
            <v-col cols="12">
              <v-btn
                color="primary"
                @click="saveEventChanges"
              >
                <div v-if="!dulplicateEvent">
                  Edit event
                </div>
                <div v-if="dulplicateEvent">
                  Duplicate event
                </div>
              </v-btn>
              <v-btn
                text
                @click="editEventStep = 2"
              >
                Previous
              </v-btn>
              <v-btn
                text
                @click="toggleDialog()"
              >
                Cancel
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>
      </v-stepper>
    </v-form>
  </v-container>
</template>

<script>
import {
  ADS_Colors,
  AdsTextField,
  AdsSelect,
  AdsTextArea,
  DatePicker,
  Alert,
} from '@nswdoe/doe-ui-core';

import { useAuthStore } from '@/stores/auth';
import { useInitiativeTypesStore } from '@/stores/initiativeTypes';
import { useCategoriesStore } from '@/stores/categories';
import { useActivityTypesStore } from '@/stores/activityTypes';
import { useTermsStore } from '@/stores/terms';
import { useFormatsStore } from '@/stores/formats';
import { useAudiencesStore } from '@/stores/audiences';
import { usePresenterTypesStore } from '@/stores/presenterTypes';

import { zonedTimeToUtc } from 'date-fns-tz';

export default {
  name: 'EditEvent',
  components: {
    AdsTextField,
    AdsSelect,
    AdsTextArea,
    DatePicker,
    Alert,
  },
  props: {
    event: {
      type: Object,
      default: () => ({}),
    },
    dulplicateEvent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editEvent: {},
      showStepError: false,
      startTimeDialog: false,
      endTimeDialog: false,
      editEventStep: 1,
      ADS_Colors,
      validation: {
        required: (value) => !!value || 'This field is required',
        number: (value) =>
          !value || isNaN(parseFloat(value)) || parseFloat(value) <= 0
            ? 'Please enter a number greater than 0'
            : true,
        url: (value) =>
          !value ||
          new RegExp(
            '^(https?:\\/\\/)?([\\da-z.-]+)\\.([a-z.]{2,6})([\\/\\w .-]*)*\\/?$'
          ).test(value) ||
          'Invalid URL',
      },
      schools: [],
      transformedSchoolData: [],
      regions: [],
      schoolGroups: [],
    };
  },
  watch: {
    'editEvent.start_date'(newStartDate) {
      if (
        newStartDate &&
        (!this.editEvent.end_date ||
          new Date(this.editEvent.end_date) < new Date(newStartDate))
      ) {
        this.editEvent.end_date = newStartDate;
      }
    },
    'editEvent.format'(newVal) {
      if (newVal === 'In-person') {
        this.$nextTick(() => {
          this.setupGoogleMapsAutocomplete();
        });
      }
    },
    event: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.editEvent = { ...this.event };
        } else {
          this.resetForm();
        }
      }
    }
  },
  computed: {
    filteredSchoolGroups() {
      if (
        !this.editEvent.selected_region_ids ||
        this.editEvent.selected_region_ids.length === 0
      ) {
        return [];
      }

      return this.schoolGroups.filter((item) =>
        this.editEvent.selected_region_ids.includes(item.region_id)
      );
    },
    filteredSchools() {
      let schools = [];
      for (let region of this.schools) {
        for (let group of region.groups) {
          for (let school of group.schools) {
            if (
              (!this.editEvent.selected_group_ids ||
                this.editEvent.selected_group_ids.includes(
                  school.school_group_id
                )) &&
              (!this.editEvent.school_type ||
                this.editEvent.school_type === school.school_type)
            ) {
              schools.push(school);
            }
          }
        }
      }
      return schools;
    },
    registrationRequiredMessage() {
      if (
        this.editEvent.category == 'CIT Meeting' ||
        this.editEvent.category == 'Executive Presentation' ||
        this.editEvent.category == 'Updates to School Teams' ||
        this.editEvent.category == 'Professional Learning'
      ) {
        return 'Registration is not available for this Category';
      } else if (
        this.editEvent.category == 'Student / School Event or Activity' ||
        this.editEvent.category == 'Job Readiness Workshop' ||
        this.editEvent.category == 'Apprenticeship and Traineeship Headstart' ||
        this.editEvent.category == 'VET Ambassador Event'
      ) {
        return 'If your audience includes Students, registration will be required for this Category';
      }

      return '';
    },
    initiativeTypes() {
      const initiativeTypesStore = useInitiativeTypesStore();
      return initiativeTypesStore.items;
    },
    categories() {
      const categories = useCategoriesStore();
      return categories.items;
    },
    filteredCategories() {
      if (!this.editEvent.initiative_type) {
        return this.categories;
      }
      if (this.editEvent.initiative_type == 'VET Ambassadors') {
        return ['VET Ambassador Event'];
      }

      if (this.editEvent.initiative_type == 'Job Readiness') {
        return ['Job Readiness Workshop'];
      }

      if (
        this.editEvent.initiative_type ==
        'Apprenticeship and Traineeship Headstart'
      ) {
        return ['Apprenticeship and Traineeship Headstart'];
      }

      if (this.editEvent.initiative_type == 'Innovating Careers Education') {
        return [
          'CIT Meeting',
          'Executive Presentation',
          'Professional Learning',
          'Student / School Event or Activity',
          'Updates to School Teams',
        ];
      }

      return this.categories;
    },
    activityTypes() {
      const activityTypes = useActivityTypesStore();
      return activityTypes.items;
    },
    filteredActivities() {
      if (!this.editEvent.initiative_type) {
        return this.activityTypes;
      }
      if (this.editEvent.initiative_type == 'VET Ambassadors') {
        return ['Presentation Session'];
      }

      if (this.editEvent.initiative_type == 'Job Readiness') {
        return ['Workshop'];
      }

      if (
        this.editEvent.initiative_type ==
        'Apprenticeship and Traineeship Headstart'
      ) {
        return ['Course'];
      }

      return this.activityTypes;
    },
    terms() {
      const terms = useTermsStore();
      return terms.items;
    },
    formats() {
      const formats = useFormatsStore();
      return formats.items;
    },
    audiences() {
      const audiences = useAudiencesStore();
      return audiences.items;
    },
    filteredAudiences() {
      if (!this.editEvent.category) {
        return this.audiences;
      }
      if (
        this.editEvent.category == 'CIT Meeting' ||
        this.editEvent.category == 'Executive Presentation' ||
        this.editEvent.category == 'Updates to School Teams' ||
        this.editEvent.category == 'Professional Learning'
      ) {
        return this.audiences.filter(
          (audience) => audience !== 'High school students'
        );
      }

      return this.audiences;
    },
    presenterTypes() {
      const presenterTypes = usePresenterTypesStore();
      return presenterTypes.items;
    },
    loggedInUserRole() {
      const auth = useAuthStore();
      return auth.role;
    },
  },
  mounted() {
    Promise.all([
      this.$api.regions.fetch().then((results) => {
        this.regions = results.regions;
      }),

      this.$api.schools.fetchNestedData().then((results) => {
        this.schools = results;
      }),

      this.$api.schools.fetchGroups().then((results) => {
        this.schoolGroups = results.groups;
      }),
    ]).catch(() => {});

    
  },
  methods: {
    setupGoogleMapsAutocomplete() {
      // Check if the Google Maps API is loaded and the input element exists
      if (typeof google !== 'undefined' && this.$refs.addressInput) {
        const input = this.$refs.addressInput.$el.querySelector('input');
        // eslint-disable-next-line no-undef
        const autocomplete = new google.maps.places.Autocomplete(input, {
          componentRestrictions: { country: 'au' },
        });

        // Listen for the place_changed event
        autocomplete.addListener('place_changed', () => {
          const place = autocomplete.getPlace();
          if (place.formatted_address) {
            this.newEvent.venue_address = place.formatted_address;
          }
        });
      } else {
        // Handle the case where the Google Maps API is not loaded yet
        // console.error('Google Maps API not loaded or input element not found');
      }
    },
    allowedDates: (val) => parseInt(val.split('-')[2], 10) % 2 === 0,
    isValidStep(step) {
      let isValid = true;

      // Validate step 1 fields
      if (step === 1) {
        isValid =
          !!this.editEvent.title &&
          !!this.editEvent.initiative_type &&
          !!this.editEvent.category &&
          !!this.editEvent.activity_type &&
          !!this.editEvent.description;
      }

      // Validate step 2 fields
      if (step === 2) {
        isValid =
          !!this.editEvent.start_date &&
          !!this.editEvent.end_date &&
          !!this.editEvent.start_time &&
          !!this.editEvent.end_time &&
          !!this.editEvent.term &&
          (!isNaN(parseFloat(this.editEvent.capacity)) && parseFloat(this.editEvent.capacity) > 0) &&
          !!this.editEvent.format &&
          (this.editEvent.format === 'In-person'
            ? !!this.editEvent.venue_address
            : !!this.editEvent.access_link);
      }

      // Validate step 3 fields
      if (step === 3) {
        isValid =
          !!this.editEvent.school_type &&
          Array.isArray(this.editEvent.schools) &&
          this.editEvent.schools.length > 0 &&
          Array.isArray(this.editEvent.audiences) &&
          this.editEvent.audiences.length > 0;
      }

      return isValid;
    },
    moveToStep(targetStep) {
      this.showStepError = false;
      if (this.isValidStep(this.editEventStep)) {
        this.editEventStep = targetStep;
      } else {
        this.showStepError = true;
      }
    },
    saveEventChanges() {
      this.showStepError = false;
      if (this.isValidStep(this.editEventStep)) {
        const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        const startInUserTimezone = `${this.editEvent.start_date}T${this.editEvent.start_time}:00`;
        const endInUserTimezone = `${this.editEvent.end_date}T${this.editEvent.end_time}:00`;

        // Convert user's time to UTC using date-fns-tz
        this.editEvent.start_datetime = zonedTimeToUtc(
          startInUserTimezone,
          userTimezone
        ).toISOString();
        this.editEvent.end_datetime = zonedTimeToUtc(
          endInUserTimezone,
          userTimezone
        ).toISOString();

        // set id to null if we want to duplciate event
        const id = this.dulplicateEvent ? null : this.editEvent.id;

        this.$api.events.save(id, this.editEvent).then((response) => {
          this.editEvent.id = response.id;
          this.toggleDialog();
        });
      } else {
        this.showStepError = true;
      }
    },
    toggleDialog() {
      this.$emit('eventEdited', this.editEvent.title);
    },
    resetForm() {
      this.$refs.editEventForm.reset();
      this.editEvent = {};
      this.editEventStep = 1;
    }
  },
};
</script>

<style lang="scss" scoped></style>
