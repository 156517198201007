<template>
  <v-card
    :class="{'rounded-0': $vuetify.breakpoint.xsOnly}"
  >
    <v-card-title>
      <span
        class="heading"
        @click.stop
      >
        {{ heading }}
      </span>
      <v-btn
        aria-label="close menu"
        class="close-button"
        icon
        small
        @click="$emit('close:menu', false)"
      >
        <v-icon>
          close
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-actions @click.stop>
      <JoinedDatePicker
        ref="datePicker"
        v-model="dates"
      />
    </v-card-actions>
  </v-card>
</template>

<script>
import JoinedDatePicker from '@/components/JoinedDatePicker';

export default {
  name: 'EppContent',
  prop: ['value'],
  components: {
    JoinedDatePicker
  },
  data() {
    return {
      dates: this.value
    };
  },
  props: {
    heading: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    }
  },
  watch: {
    value(val) {
      this.$refs.datePicker.updateDates(val);
    },
    dates(val) {
      this.$emit('input', val);
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@nswdoe/doe-ui-core/styles/variables.scss';

.v-card, .v-dialog > .v-card {
  .v-card__title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
    font-size: 16px;
    font-weight: bold;

    .heading {
      flex: 1;
      padding: 12px 0 12px 16px;

      .theme--light & {
        color: $ads-navy;
      }

      .theme--dark & {
        color: $ads-navy-dark;
      }
    }

    .close-button {
      margin: 12px 16px 12px 0;

      .theme--light & {
        color: $ads-navy;
      }

      .theme--dark & {
        color: $ads-navy-dark;
      }

      &:focus {
        outline: none !important;
        border: 2px solid $ads-navy;

        .theme--dark & {
          border-color: $ads-navy-dark;
        }
      }
    }
  }

  .v-card__actions {
    padding: 0;
  }

  .theme--dark & {
    background-color: $ads-dark;
  }
}

@media only screen and (max-width: 600px) {
  .v-card {
    width: 100vw;
  }
}
</style>
