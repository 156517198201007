import {get} from '../http';
import qs from 'qs';

const fetch = id => get('studentRetrieve?id='+id);
const fetchForSession = (id, name, srn) => {
  if (srn) {
    return get('studentsRetrieveFromSessionSchools?id=' + id + '&' + qs.stringify({ name }) + '&' + qs.stringify({ srn }))
  } else {
    return get('studentsRetrieveFromSessionSchools?id=' + id + '&' + qs.stringify({ name }))
  }
};

export default {
  fetch,
  fetchForSession
};
