<template>
  <Banner
    :heading="heading"
    :subtitle="subtitle"
    class="mr-0"
    contentClass="container--fluid"
    backgroundImageUrl="/images/banner.png"
  >
    <template #icon>
      <v-icon
        size="xxx-large"
        :class="iconOutlined ? 'material-icons-outlined' : null"
      >
        {{ icon }}
      </v-icon>
    </template>
    <template #subtitle>
      <slot name="subtitle" />
    </template>
    <template #rightPannel>
      <slot name="rightPannel" />
    </template>
  </Banner>
</template>

<script>
import {Banner} from '@nswdoe/doe-ui-core';

export default {
  name: 'EppHeader',
  components: {
    Banner
  },
  props: {
    heading: {
      type: String,
      default: undefined
    },
    subtitle: {
      type: String,
      default: undefined
    },
    icon: {
      type: String,
      default: undefined
    },
    iconOutlined: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../scss/variables";

.banner:deep {
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;
    min-height: 118px;
  }
  .appTitle {
    line-height: 1;
    margin-top: 0.25em;
    margin-bottom: 0.25em;
  }
  a {
    color: $ads-navy-dark;
  }
  .v-icon {
    color: inherit;
    font-size: 48px;
  }

  @media print {
    color: $ads-dark;
  }
}
</style>
