import { get, post } from '../http';

const fetch = () => get('schoolsRetrieve');
const fetchGroups = () => get('schoolGroupsRetrieve');
const fetchRegions = () => get('regionsRetrieve');
const fetchNestedData = () => get('regionGroupSchoolRetrieve');
const save = (id, data) => {
  let url = 'schoolCreate';
  if (id) {
    url += '?id=' + id;
  }
  return post(url, data);
};

const search = (schoolName) => post('schoolSearch', {name: schoolName});

export default {
  fetch,
  fetchGroups,
  fetchRegions,
  fetchNestedData,
  save,
  search,
};
