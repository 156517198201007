/**
 * This is the entry point for all API requests.
 * import API modules and expose them to components via Vue.prototype.$api
 */
import access from './modules/access';
import faq from './modules/faq';
import events from './modules/events';
import essentials from './modules/essentials';
import notifications from './modules/notifications';
import registrants from './modules/registrants';
import schools from './modules/schools';
import regions from './modules/regions';
import schoolGroups from './modules/school_groups';
import sessions from './modules/sessions';
import staff from './modules/staff';
import students from './modules/students';
import user from './modules/user';

export default {
  access,
  faq,
  events,
  essentials,
  notifications,
  registrants,
  schools,
  regions,
  schoolGroups,
  sessions,
  staff,
  students,
  user
};
