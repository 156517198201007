import {get, post, del} from '../http';

const fetch = () => get('faqRetrieve');
const save = (id, data) => {
  let url = 'faq';
  if (id) {
    url += 'Update?id='+id;
  } else {
    url += 'Create';
  }
  return post(url, data);
};
const remove = id => del('faqDelete?id='+id);

export default {
  fetch,
  save,
  remove
};
